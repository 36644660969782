import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './swiperstyles.css';

export const SwiperWrapper = ({ children }) => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={20}
      navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      breakpoints={{
        // Configure responsive breakpoints
        320: {
          slidesPerView: 1, // Show 1 slide on small screens
        },
        480: {
          slidesPerView: 2, // Show 2 slides on medium screens
        },
        768: {
          slidesPerView: 3, // Show 3 slides on tablets
        },
        1024: {
          slidesPerView: 4, // Show 4 slides on larger screens
        },
      }}
    >
      {children}
    </Swiper>
  );
};
