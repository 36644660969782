import React from 'react';
import {
  Card,
  Text,
  Image,
  Stack,
  Divider,
  ButtonGroup,
  Button,
  CardHeader,
  Heading,
  CardBody,
  CardFooter,
} from '@chakra-ui/react';

export const TemplateCard = () => {
  return (
    <Card
      maxW={{ base: '100%', sm: '200px' }}
      size={'sm'}
      minW="200px"
      transition="transform 0.2s ease-in-out"
    >
      <CardBody>
        <Image
          src="https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-004.jpg"
          alt="clippy templates"
          borderRadius="lg"
        />
      </CardBody>
      <Divider />
      <CardFooter>
        <Text color="tuna" size="md">
          Template1
        </Text>
      </CardFooter>
    </Card>
  );
};
