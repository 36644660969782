import React from 'react';
import { Redirect } from 'react-router-dom';
import { Heading } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@chakra-ui/react';
import config from '../../../config';
import { UserLibrary } from './Library';
import { WithProjectModalHoc } from 'views/dashboard/components/new-project-hoc';
import { PopularTemplates } from './popular-templates';

export const AllLibraryProjects = WithProjectModalHoc(() => {
  const { user } = useAuth0();

  if (user?.[config.auth.claims.loginsCount] === 1) {
    return <Redirect to={'/onboarding'} />;
  }
  return (
    <Box p={[2, 2]} data-testid={'all-librrary-page-wrapper'}>
      <Heading>Templates</Heading>
      <Box p={[2, 4]}></Box>
      <PopularTemplates title="Top Pick's" />
      <Box p={[2, 2]}></Box>
      <PopularTemplates title="Intro & Outro" />
      <Box p={[2, 2]}></Box>
      <PopularTemplates title="Celebrations" />
      <Box p={[2, 2]}></Box>
      <PopularTemplates title="Games" />
    </Box>
  );
});
