import React from 'react';
import { Box, Stack, Text, Spacer } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { TemplateCard } from './template-card';
import { SwiperWrapper } from './swiper-wrapper';

export const PopularTemplates = ({ title }) => {
  return (
    <>
      <Box p={[2, 4]} bg={'charade'}>
        <Text fontWeight={'bold'} fontSize={'lg'} mb={2}>
          {title}
        </Text>

        <Stack
          direction={['column', 'row']}
          spacing="10px"
          p={4}
          whiteSpace="nowrap"
        >
          <SwiperWrapper>
            <SwiperSlide>
              <TemplateCard />
            </SwiperSlide>
            <SwiperSlide>
              <TemplateCard />
            </SwiperSlide>
            <SwiperSlide>
              <TemplateCard />
            </SwiperSlide>
            <SwiperSlide>
              <TemplateCard />
            </SwiperSlide>
            <SwiperSlide>
              <TemplateCard />
            </SwiperSlide>
            <SwiperSlide>
              <TemplateCard />
            </SwiperSlide>
            <SwiperSlide style={{ width: 'auto' }}>
              <TemplateCard />
            </SwiperSlide>
            <SwiperSlide>
              <TemplateCard />
            </SwiperSlide>
            <SwiperSlide>
              <TemplateCard />
            </SwiperSlide>
          </SwiperWrapper>
        </Stack>
      </Box>
    </>
  );
};
