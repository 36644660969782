import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Flex,
  Image,
  IconButton,
  Link,
  Icon,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoHomeFill } from 'react-icons/go';
import { Button } from 'components';
import { NewProjectContext } from 'providers/new-project-context';
import { useFontSizes } from 'hooks/useFontSizes';

const CreateNewProject = () => {
  const { setIsOpen } = useContext(NewProjectContext);
  const { fontSize, headingFontSize } = useFontSizes();
  const [isSmallScreen] = useMediaQuery('(max-width: 600px)');
  // screen less than 600px

  return (
    <Flex gap={2}>
      {!isSmallScreen && (
        <Button
          rightIcon={<FontAwesomeIcon icon={['fas', 'plus']} size={fontSize} />}
          isFullWidth
          size={['md', 'lg']}
          p={[2, 2, 2, 2, 2]}
          colorScheme={'blue'}
          label={'Create Project'}
          onClick={() => {
            setIsOpen(() => true);
          }}
          data-testid="create-project"
          id="create-project"
        />
      )}
      {isSmallScreen && (
        <IconButton
          colorScheme="blue"
          aria-label="Create Project"
          icon={<FontAwesomeIcon icon={['fas', 'plus']} size={fontSize} />}
          data-testid="create-project"
          id="create-project"
        />
      )}
    </Flex>
  );
};

export default CreateNewProject;
